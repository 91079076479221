<template>
  <div>
    <div v-if="!isMobile">
      <div v-for="(item, index) in videoList" :key="index">
        <videoindex
          :item="item"
          :isones="index == 0 ? true : false"
          :ref="'deo' + index"
          @video_runpage="video_runpage"
        ></videoindex>
      </div>
    </div>

    <div v-if="isMobile">
      <!-- <mobilehendtitle
        title="卓阳电站"
        address="卓阳电站示范项目"
        :imgurl="lbt1"
      ></mobilehendtitle> -->
      <div class="">
        <div v-for="(item, index) in videoList" :key="index">
          <mobilenewtest
            :id="'ads' + index"
            :item="item"
            :ref="'deo' + index"
            :isones="index == 0 ? true : false"
            @video_runpage="video_runpage"
          ></mobilenewtest>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videoindex from "@/components/pc/videoindex";

import p1 from "@/assets/images/transtation/p1.jpg";
import p2 from "@/assets/images/transtation/p2.jpg";
import p3 from "@/assets/images/transtation/p3.jpg";
// 手机
import mp1 from "@/assets/images/transtation/mp1.jpg";
import mp2 from "@/assets/images/transtation/mp2.jpg";
import mp3 from "@/assets/images/transtation/mp3.jpg";
import mobilenewtest from "@/components/mobile/mobilenewtest";

import { mixins } from "@/common/scrollmixin.js";
import { mapGetters } from "vuex";

import { look_mixin } from "@/utils/looktime.js";
export default {
  components: {
    // hendtitle,
    // contentbox,
    // imgleftbox,
    videoindex,
    // mobilecontentcard,
    mobilenewtest,
    // mobilehendtitle,
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },

  mixins: [mixins, look_mixin],
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.videoList.forEach((item) => {
      item.pages = true;
    });
    if (this.isMobile) {
      this.videoList[0].src = mp1;
      this.videoList[1].src = mp2;
      this.videoList[2].src = mp3;
    }
  },
  data() {
    return {
      scroll: 0,
      videoList: [
        {
          id: 1,
          title: "关岭县卓阳江西坪农业光伏电站",
          title5: "关岭县卓阳",
          title6: "江西坪农业光伏电站",
          tofidetitle: true, // 是否否定主标题
          desc: "多功能于一体的生态农业光伏电站",
          src: p3,
          isvideo: false,
          pages: false,
          runurl: `/transtationdetial/${3}`,
        },
        {
          id: 2,
          title: "关岭县岗乌卓阳农业光伏项目",
          title5: "关岭县岗乌",
          title6: "卓阳农业光伏项目",
          tofidetitle: true, // 是否否定主标题
          desc: "在2019年贵州省光伏项目单体建设容量中位列第一",
          src: p2,
          isvideo: false,
          pages: false,
          runurl: `/transtationdetial/${2}`,
        },

        {
          id: 3,
          title: "独山县卓申光伏电站",
          title5: "独山县卓申光伏电站",
          title6: "",
          tofidetitle: true, // 是否否定主标题
          desc: "2022年被评为清洁能源示范项目",
          src: p1,
          isvideo: false,
          pages: true,
          runurl: `/transtationdetial/${1}`,
        },
      ],
    };
  },
  methods: {
    video_runpage(e) {
      this.user_btn_click(e.title, e.runurl, e.btn_name);
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 第一个屏幕
      if (this.scroll < this.positionList[1].top + 25) {
        this.videoList[0].pages = true;
        this.videoList[1].pages = false;
        this.videoList[2].pages = false;
        // this.videoList[3].pages = false;
      }
      // 第二个屏幕
      if (
        this.scroll < this.positionList[2].top - 100 &&
        this.scroll > this.positionList[1].top - 100
      ) {
        this.videoList[0].pages = false;
        this.videoList[1].pages = true;
        this.videoList[2].pages = false;
        // this.videoList[3].pages = false;
      }
      // 第三个屏幕
      if (this.scroll > this.positionList[2].top - 100) {
        this.videoList[0].pages = false;
        this.videoList[1].pages = false;
        this.videoList[2].pages = true;
        // this.videoList[3].pages = false;
      }
      // 第四个屏幕
      // if (this.scroll > this.positionList[3].top - 150) {
      //   this.videoList[0].pages = false;
      //   this.videoList[1].pages = false;
      //   this.videoList[2].pages = false;
      //   // this.videoList[3].pages = true;
      // }
    },
  },
};
</script>

<style>
</style>